<template>
  <div>
    <h4>Suppliers</h4>

    <div class="card mt-2">
      <div class="card-body">
        <router-link
          :to="{name: 'newSupplier'}"
          class="btn btn-outline-primary float-right"
        >
          <i class="fas fa-plus mr-2" />
          New Supplier
        </router-link>

        <h4>List</h4>

        <vue-table
          v-if="vTable.headers"
          ref="vtable"
          :values="suppliers"
          :headers="vTable.headers"
          :actions="vTable.actions"
          :options="vTable.options"
          @changed="getData"
          @onDelete="onDelete"
          @onShowDetails="onShowDetails"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data: () => ({
    vTable: {
      headers: [
        {
          title: 'id',
          sortable: true,
        },
        {
          title: 'name',
          sortable: true,
        },
        {
          title: 'transfer_fee',
          mask: 'transfer fee',
          sortable: true,
        },
        {
          title: 'accommodation',
          mask: 'accommodations',
          callback: (item) => item.length,
        },
      ],
      actions: [
        {
          buttonClass: 'btn-info btn-sm',
          callback: 'onShowDetails',
          tooltip: 'Show Details',
          icon: 'fas fa-info',
        },
        {
          buttonClass: 'btn-danger btn-sm',
          callback: 'onDelete',
          tooltip: 'Delete',
          icon: 'fas fa-times',
        },
      ],
      options: {
        tableClass: 'table table-striped',
      },
    },
  }),
  computed: {
    ...mapGetters([
      'suppliers',
    ]),
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    ...mapActions([
      'getSuppliers',
      'deleteSupplier',
    ]),
    getData(evt = {}) {
      this.getSuppliers(evt).catch(this.showError);
    },
    resetTable() {
      this.$refs.vtable.init();
    },
    onDelete(item) {
      this.fireConfirm().then(() => {
        this.deleteSupplier(item)
          .then(() => {
            this.$noty.success('Supplier deleted succesfully');
            this.resetTable();
          })
          .catch(this.showError);
      });
    },
    onShowDetails(item) {
      this.$router.push({
        name: 'supplierDetails',
        params: {
          id: item.id,
        },
      });
    },
  },
};
</script>

<style>

</style>
